/**
 * WooCommerce Product
 */

.woocommerce,
.woocommerce-page {

  .button {
    padding: 20px !important;
  }

  .site-main {
    .container {
      max-width: 960px;
    }
  }

  .variations {
    .label {
      line-height: 38px !important;
    }

    select {
      height: 38px;
    }
  }

  span.onsale {
    background-color: $dark-green !important;
  }

  div {
    &.product {
      div.images {
        .woocommerce-product-gallery__image {
          &:first-child {
            width: 445px;
            margin-bottom: 15px;
          }
          &:nth-child(n+2) {
            display: inline-block;
            margin-right: 15px;
            // width: 138px !important;
            // height: 138px;
            margin-bottom: 15px;
          }
        }
      }

      form.cart {
        margin-bottom: 30px !important;
      }

      div {
        &.summary {
          width: 44% !important;

          .product_title {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            margin-bottom: 0;
          }
          .star-rating {
            span::before {
              color: $dark-green;
            }
            padding-bottom: 10px;
            border-bottom: 3px solid #FFC402;
          }

          .woocommerce-review-link {
            font-size: 14px;
            color: $dark-green;
            line-height: 32px;
            margin-left: 14px;
          }

          .was {
            font-size: 18px;
            color: #169B3A;
            margin-bottom: 14px;
          }

          .now {
            font-size: 24px;
            color: #169B3A;
            font-weight: 700;
          }

          .woocommerce-product-details__short-description {
            font-size: 14px;
          }

          .qty {
            width: 132px;
            height: 52px;
            background-color: #F8F8F8;
            border: 0.5px solid #3A3A3A;
            margin: 7px 0;
          }

          .single_add_to_cart_button {
            padding: 18px 75px;
            margin-left: 30px;
            background-color: #169B3A !important;
            font-size: 14px;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
          }
        }

        &.images {
          img {
            width: 450px;
            height: auto;
          }
        }
      }
    }
  }
}

.cart {
  .single_add_to_cart_button {
    float: right !important;
  }
}

.yith-ywraq-add-to-quote {
  clear: both;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  .yith-ywraq-add-button {
    .add-request-quote-button {
      font-size: 14px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
    }
  }
}

.single-product {
  .qty {
    height: unset !important;
  }
}

.woocommerce div.product .woocommerce-product-rating {
  margin-bottom: 20px !important;
}
.woocommerce div.product div.images .woocommerce-product-gallery__image:nth-child(n+2) {
  float: left;
}

.technical-information {
  clear: both;
  max-width: 1140px;
  margin: 30px auto;
  padding: 20px;
  border: 1px dashed #000000;

  .attachments {
    display: flex;
    flex-flow: row wrap;
    margin: 0;

    li {
      flex-basis: 47%;
      list-style-type: none !important;
      background-image: url('assets/images/document.svg');
      background-repeat: no-repeat;
      background-position: 0% 15%;
      background-size: auto;
      padding-left: 25px;
      margin-bottom: 20px;
    }
  }
}

.upsells-product-container {
  background-color: #F5F5F5;
  padding: 25px 30px 30px;
  margin-top: 30px;
  margin-bottom: 60px;
  height: 100%;

  .woocommerce-loop-product__title {
    font-size: 20px !important;
    color: $black;
    font-weight: 400;
  }

  .price {
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .products .product {
    margin-bottom: 0 !important;
  }
}

.woocommerce-tabs {
  .tabs {
    margin: 0 !important;
    padding: 0 0 0 5px !important;

    &::before {
      border-bottom: 1px solid #A7A7A7 !important;
    }

    li {
      background-color: #F5F5F5 !important;
      border: 0.5px solid #A7A7A7 !important;
      border-radius: 0 !important;
      padding: 0 1.5em !important;

      &.active {
        background-color: #FCFCFC !important;
        border-bottom-color: #FCFCFC !important;
      }

      &::before {
        border-bottom-right-radius: 0 !important;
        width: 0 !important;
        height: 0 !important;
      }

      &::after {
        border-bottom-left-radius: 0 !important;
        width: 0 !important;
        height: 0 !important;
      }
    }
  }

  .woocommerce-Tabs-panel {
    border-bottom: 0.5px solid #A7A7A7;
    border-left: 0.5px solid #A7A7A7;
    border-right: 0.5px solid #A7A7A7;
    background-color: #FCFCFC;
    padding: 30px !important;
  }
}

.add_to_cart_button,
.product_type_simple,
.product_type_variable {
  @include btn;
  font-size: 14px !important;
  padding: 20px !important;
}

.product_meta {
  font-size: 12px;
}

/**
* Product categories
**/

.woocommerce,
.woocommerce-page {
  .woocommerce-result-count {
    margin: 0 0 60px !important;
  }
  ul.products {
    li.product {
      .button {
        // position: absolute;
        // bottom: 0;
        width: 81%;
        max-width: 210px;
        text-align: center;
        font-size: 14px !important;
      }
    }
  }

  .products ul,
  ul.products {
    display: flex;
    flex-flow: row wrap;
  }

  ul.products li.product {
    display: flex;
    flex-flow: column nowrap;
  }

  ul.products li.product .button {
    margin-top: auto !important;
    display: table;
  }
}

.product-category {
  h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }

  .category-filter {
    .filter {
      background-color: #EEEEEE;
      padding: 30px;

      .woocommerce-category-sidebar {
        margin: 0 0 20px;

        h2 {
          color: $dark-green;
          font-size: 20px;
        }

        .product-categories,
        .woocommerce-widget-layered-nav-list {
          list-style-type: none;
          margin: 0;

          a {
            text-decoration: none;
            font-size: 14px;
          }

          .count {
            float: right;
          }
        }

        .product-categories {
          .cat-item {
            padding: 0 0 4px;
          }
        }
      }


      /**
      * Search form
      **/
      form.woocommerce-product-search {
        clear: both;
        display: block;
        position: relative;
        margin-bottom: 1em;

        input[type=search] {
          border: 1px solid #ddd;
          box-shadow: none;
          width: 100%;
          margin: 0;
          padding: 11px 9px;
          text-transform: capitalize;
          font-size: 12px;
        }

        button[type=submit] {
          border: none;
          cursor: pointer;
          height: 39px;
          position: absolute;
          width: 43px;
          right: 1px;
          background-image: url(assets/images/search.svg);
          background-repeat: no-repeat;
          background-size: 20px;
          background-position: center;
          top: 1px;
          font-size: 0;
        }
      }
    }
  }

  .category-layout {
    max-width: 684px;
    margin-left: 36px;
    margin-bottom: 60px;

    .star-rating {
      display: none !important;
    }

    .price {
      font-size: 20px !important;
    }
  }

  .title,
  .woocommerce-loop-product__title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }

  .title {
    font-size: 30px !important;
  }

  .woocommerce-loop-product__title {
    font-size: 24px !important;
  }
}

/**
 * Price filter widget
 */
.price_slider {
  margin-bottom: 1em;
}

.price_slider_amount {
  text-align: right;
  line-height: 24px;
  font-size: 12px;
  color: $dark-green;

  .button {
    font-size: 12px !important;
    background: #ffffff !important;
    border-radius: 20px !important;
    color: $dark-green !important;
    font-weight: 400 !important;
    text-transform: uppercase;
    border: 1px solid $dark-green !important;
    float: left;
    padding: 5px 10px !important;
  }
}

.ui-slider {
  position: relative;
  text-align: left;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 13px;
  height: 13px;
  background-color: #ffffff;
  border: 1px solid $dark-green;
  border-radius: 1em;
  cursor: ew-resize;
  outline: none;

  /* rtl:ignore */
  margin-left: -0.5em;
}

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: 0.7em;
  display: block;
  border: 0;
  border-radius: 1em;
  background-color: $dark-green;
}

.price_slider_wrapper .ui-widget-content {
  border-radius: 1em;
  background-color: #ffffff;
  border: 0;
}

.ui-slider-horizontal {
  height: 14px;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
  left: -1px;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: -1px;
}

.woocommerce-pagination {
  text-align: left !important;
  float: left;

  ul {
    border: 0 !important;

    li {
      border-right: 0 !important;
      width: 28px;
      height: 38px;

      a {
        background-color: $dark-green;
        color: #fff;
        min-width: unset !important;
        font-size: 14px !important;
        padding: 10px !important;

        &:focus,
        &:hover {
          background-color: #fff !important;
          border: 1px solid $dark-green;
          padding: 9px !important;
        }
      }

      a.next,
      a.prev {
        display: none !important;
      }

      span.current {
        background-color: #fff !important;
        min-width: unset !important;
        font-size: 14px !important;
        padding: 10px !important;
      }
    }
  }
}

.itemsorder {
  display: inline-flex;
  float: right;

  .sortby {
    width: 46px;
    margin: 0 10px;
  }
}

// .product-template-default {
//   .mid-grey-bg-no-pad {
//     margin-top: 70px;
//   }
// }

.add-request-quote-button {
  width: 91%;
  text-align: center;
}

.value {
  select {
    float: right;
    margin-right: 0 !important;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .breadcrumbs {
    padding-left: 25px;
  }

  .single-product {
    .col-6 {
      flex-basis: 100% !important;
      width: 100%;
    }
  }

  .summary {
    padding-top: 2em;
  }

  .product-container {
    padding: 0 25px;
  }

  .woocommerce-page div.product div.images {
    float: unset !important;
    width: 100% !important;
  }

  .woocommerce-page div.product div.summary {
    clear: both !important;
    float: unset !important;
    width: 100% !important;
  }

  .woocommerce-product-gallery__image:first-child {
    margin: 0 auto 15px;
  }

  .upsells-product-container {
    max-height: unset;

    ul.products li.product {
      margin-bottom: 0 !important;
    }
  }

  .product-category {
    .category-filter {
      &.col-3 {
        flex-basis: 100%;
        padding: 0 25px;
      }
    }

    .category-layout {
      max-width: unset;
      margin-left: unset;
      margin-top: 25px;

      &.col-9 {
        flex-basis: 100%;
        padding: 0 25px;
      }
    }
  }
}

.trade-text {
  color: #F1132D;
  margin-bottom: 15px;

  svg {
    fill: #F1132D;
    width: 13px;
  }
}

.entry-summary {
  .trade-text {
    padding: 14px 0 30px;
    font-size: 18px;

    svg {
      margin-right: 15px;
    }

    a {
      color: #F1132D;
    }
  }

  .single_add_to_cart_button {
    width: 54%;
  }
}

.product_meta {
  color: #3A3A3A;
}

.post-type-archive-product,
.tax-product_cat {
  .trade-text {
    font-size: 12px;
    line-height: 14px;

    svg {
      height: 10px;
    }
  }

  .was {
    display: none;
  }

  .original-computed-price {
    font-size: 20px;
  }

  .wholesale_price_minimum_order_quantity,
  .wholesale_price_order_quantity_step {
    display: none !important;
  }

  .wholesale_price_title {
    font-weight: 700;
  }

  .wholesale_price_container {
    ins {
      text-decoration: none;
    }
  }
}

.woocommerce-variation-description {
  .trade-text {
    display: none;
  }
}

.comment-text {
  margin: 0 !important;
}

.star-rating  {
  span {
    &::before {
      color: $dark-green;
    }
  }
}

ul.products.columns-3 {
  li.product {
    min-height: 400px;
  }
}

.woocommerce-form__input-checkbox {
  width: unset;
}

.yith-request-a-quote-page {
  .woocommerce {
    .wide {
      display: unset;
    }

    .yith-ywraq-mail-form-wrapper {
      margin-left: 0 !important;
      padding: 25px 0;
    }
  }
}

.single-product {
  .woocommerce a.added_to_cart {
    padding-top: 0;
  }

  .qty-based-discount-table-description {
    padding-top: 25px;
  }

  .order-quantity-based-wholesale-pricing-view {
    width: 100%;
  }

  .wholesale_price_minimum_order_quantity,
  .wholesale_price_order_quantity_step {
    font-size: 14px;
  }

  .woocommerce-variation-price {
    .price {
      margin-bottom: 20px;
    }
  }

  .original-computed-price {
    font-size: 18px;
    margin: 15px 0;
  }

  .order-quantity-based-wholesale-pricing-view {
    line-height: 28px;
  }

  .wholesale_price_title {
    font-weight: 700;
  }

  .wholesale_price_container {
    ins {
      text-decoration: none;
    }
  }
}
