/**
 * Global
 */

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container {
  max-width: 1140px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

$grid__cols: 12;
@for $i from 1 through $grid__cols {
  .col-#{$i} {
    flex-basis: (100 / ($grid__cols / $i) ) * 1%;

    @media screen and (max-width: 767.9px)  {
      flex-basis: 100%;
    }
  }
}


@mixin btn {
  background-color: $dark-green !important;
  color: $white !important;
  border-radius: 10px !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
}

.breadcrumbs {
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .woocommerce-breadcrumb {
    line-height: 100px;
    color: #ffffff !important;
    text-transform: uppercase;
    font-size: 12px;
    font-family: "Montserrat", sans-serif;

    a {
      color: #ffffff !important;
    }
  }
}

.trade-banner {
  margin-bottom: 70px;
  text-align: center;
  padding: 20px 0;
  background-color: #A61F2E;

  a {
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
  }
}

.button {
  @include btn;
}

.mega-menu-link {
  sup {
    color: $dark-green;
  }
}

.padding-no-banner {
  padding-bottom: 70px;
}
