/**
 * Checkout
 */
.checkout {
  .ui-widget-header, .ui-state-default {
    background: #16a542 !important;
    color: #ffffff !important;
  }

  .ui-datepicker {
    .ui-datepicker-next:before, .ui-datepicker-prev:before {
      color: #ffffff !important;
      margin-top: 0.5em;
    }
  }

  .ui-state-highlight {
    background: #FCCB01 !important;
    color: #000000 !important;
  }

  .ui-state-disabled .ui-state-default {
    color: #000000 !important;
  }

  .ui-datepicker-close {
    border-radius: 5px !important;
    border: 1px solid #16a542 !important;
    width: 88px !important;
    padding: 10px 18px 10px 18px !important;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 13px !important;
    line-height: 13px !important;
    font-weight: 300 !important;
    letter-spacing: 0.25px !important;
    color: #fff !important;
    text-transform: uppercase;
  }
}

.shop_table {
  th {
    padding: 9px 5px !important;
    color: $dark-green;
  }

  thead {
    .product-total {
      text-align: right;
    }
  }

  td {
    padding: 9px 5px !important;
  }

  .cart_item {
    td {
      padding: 15px !important;
    }
    .product-name {
      width: 100%;
      font-size: 14px !important;
    }

    .product-total {
      text-align: right;
      font-size: 14px !important;
    }
  }

  .cart-subtotal,
  .tax-total,
  .order-total {
    td {
      text-align: right;
      color: $dark-green;
      font-weight: 700;
    }
  }

  tfoot {
    th,
    td {
      color: $dark-green;
    }
  }

  .woocommerce-shipping-totals {
    label {
      color: $dark-green;
      font-weight: 700;
    }
  }
}

.woocommerce-shipping-fields {
  #ship-to-different-address {
    margin-bottom: 0;
  }

  .woocommerce-form__label {
    input[type="checkbox"] {
      width: unset;
    }

    span {
      line-height: 40px;
      margin-left: 20px;
    }
  }
}

.woocommerce #payment #place_order,
.woocommerce-page #payment #place_order {
  float: left !important;
}

#ywraq_checkout_quote {
  margin-left: 20px;
}

.wc_payment_method {
  input {
    width: unset;
  }
}

.shop_table {
  .product-thumbnail {
    img {
      max-width: unset;
      width: unset !important;
    }
  }
}

@media screen and (max-width: 767.9px)  {
  .product-remove {
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
  }
  .woocommerce-page table.cart td.actions .input-text {
    width: 100% !important;
  }

  .coupon {
    .button {
      width: 100% !important;
      margin-top: 15px !important;
    }
  }
}

.cross-sells {
  width: 40% !important;
  margin-bottom: 60px;
  height: 100%;

  @media screen and (max-width: 767.9px)  {
    width: 100% !important;
  }

  .woocommerce-loop-product__title {
    font-size: 20px !important;
    color: $black;
    font-weight: 400;
  }

  .price {
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .button {
    position: relative !important;
  }
}
