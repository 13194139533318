@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

#coBanner {
  font-family: "Montserrat", sans-serif;
  background-color: #30a444;
  color: #fff;
  box-sizing: border-box;
  padding: 35px 0;

  .banner-container {
	max-width: 960px;
	margin: 0 auto;

	.banner-information {
	  display: flex;

	  @media only screen and ( max-width: 950px ){
		flex-flow: column nowrap;
	  }

	  p {
		font-family: 'Montserrat', sans-serif;
		font-size: 1.2rem;
		text-align: center;
		display: block;
		flex-basis: 33%;
		color: #ffffff;

		svg {
		  display: inline-block;
		  transform: translate(-8px, 18px);
		  height: 45px;
		  width: 45px;
		}

		.count-up:not(.counted) {
		  display: none;
		}
	  }
	}

	.banner-link {
	  text-align: center;
	  a, p {
		font-family: 'Montserrat', sans-serif;
		color: #ffffff;
	  }
	}
  }
}
