.woocommerce-MyAccount-navigation {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.woocommerce-info {
  border-top-color: $dark-green !important;
  line-height: 56px;

  &:before {
    color: $dark-green !important;
  }
}

.woocommerce-MyAccount-content {
  form {
    font-size: 16px;
  }
}

label {
  width: 100%;
}

input {
  width: 100%;
  margin: 12px 0;
  border: 1px solid green;
  padding: 10px;
}

fieldset {
  border: 0;
  padding: 0;
}

address {
  font-style: normal;
}

.required {
  color: #ff0000;
}

.show-password-input {
  display: none;
}

.woocommerce-account {
  mark {
    background-color: #ffffff;
  }
}

.forgot-password {
  h1 {
    font-size: 44px;
    line-height: 1.38;
    font-weight: 600;
    text-transform: uppercase;
    color: $dark-green;
  }

  .woocommerce-ResetPassword {
    .woocommerce-form-row {
      width: 100%;

      label {
        width: 100%;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 24px;
        color: $dark-green;
        font-weight: 600;
        margin-bottom: 8px;
      }
    }
  }
}

.woocommerce-form-register {
  label {
    color: $dark-green;
    font-weight: 700;
  }
}
