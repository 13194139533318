.woocommerce {
  padding-top: 50px;
  padding-bottom: 50px;
  table.cart {
    border: 0 !important;
    thead {
      font-size: 22px;
      border-bottom: 1px solid #c6c6c6 !important;
    }

    .product-thumbnail {
      width: 85px;
    }
  }

  table.shop_table {
    border: 0 !important;

    td {
      border: 0;
      border-top: 0;
    }

    .cart_item {
      td {
        padding: 25px 12px;
        font-size: 16px;
        border-top: 1px solid #c6c6c6;
        border-bottom: 1px solid #c6c6c6;
      }

      td.product-remove {
        border-left: 1px solid #c6c6c6;
        border-right: 1px solid #c6c6c6;
        color: #2e1a46!important;
        padding: 25px 0 25px 0;
        text-align: center;
      }

      td.product-thumbnail {
        padding: 25px 20px 45px;
        vertical-align: middle;
        padding-top: 0;
        padding-bottom: 0;

        img {
          width: 80px;
          height: auto;
          margin: 0 auto;
          display: block;
        }
      }

      td.product-name {
        padding: 5px;
      }

      td.product-subtotal {
        border-right: 1px solid #c6c6c6;
      }
    }

    td.product-remove {
      width: 45px;
    }

    .quantity input.qty {
      width: 45px;
      text-align: center;
      height: 22px;
      font-size: 16px;
      padding-left: 10px;
    }

    .actions {
      padding: 20px 0;
      border-top: 25px solid #fff;
      background-size: cover;
    }
  }

  a.remove {
    font-size: 1.2em;
    font-weight: 300;
    color: #2e1a46!important;
    display: inline-block !important;
  }

  button.button {
    @include btn;
  }
}


.woocommerce-page {
  table.cart {
    td.actions {
      .input-text {
        width: 100% !important;
        max-width: 211px;
        padding: 16px 20px !important;
        margin-right: 25px !important;

        @media screen and (max-width: 767.9px)  {
          max-width: unset;
        }
      }
    }
  }
}

.woocommerce-cart {
  .cart-collaterals {
    .cart_totals {
      tr {
        th,
        td {
          border-top: 0 !important;
          border-bottom: 2px solid $dark-green;
          padding: 15px 0;
        }

        td {
          text-align: right;
        }
      }
    }
  }

  .checkout-button {
    @include btn;
  }
}

.woocommerce-checkout,
.woocommerce-cart {
  .breadcrumbs {
    margin-bottom: 0;
  }
}

.woocommerce-shipping-methods {
  input[type="radio"] {
    width: unset;
    margin: 6px .4375em 0 0 !important;
  }
}
