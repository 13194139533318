/*
Theme Name: Wagner
Theme URI: http://localhost/
Author: Vinco Marketing
Version: 1.0
*/


/* ----- Normalize ----- */

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  overflow-x: hidden;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

/* ------------- */

* {
  padding: 0;
  margin: 0;
}

html {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #fff;
}

img {
  border: 0;
}

table {
  border-collapse: collapse;
  margin-bottom: 1em;
}

// table, th, td {
// 	border: 1px solid #424242;
// }

th {
  color: #424242;
  padding: 5px;
}

td {
  color: #424242;
  padding: 5px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

ol {
  list-style: outside decimal;
  margin-left: 30px;
}

.right {
  float: right;
}

.left {
  float: left;
}

.clear {
  clear: both;
}

.responsive-br {
  display: none;
}

@media only screen and (max-width: 690px) {
  .responsive-br {
    display: block;
    clear: both;
  }
}

hr {
  border: 0;
  border-top: 1px solid #424242;
  color: #424242;
  background-color: #fff;
  margin: 10px 0;
}

a {
  color: #424242;
}

a:visited {
  color: #000;
}

a:hover,
a:focus,
a:active {
  color: #093;
}

a:focus {
  outline: 0;
}

a:hover,
a:active {
  outline: 0;
}

h1,
h1#title,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1em;
  font-family: 'Open Sans', sans-serif;
}

p,
ul,
ol {
  margin-bottom: 1em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #424242;
}

#page {
  float: left;
  background-color: #fff;
  width: 100%;
  margin: 0;
  padding: 0;
}

#content-page {
  float: left;
  background-color: #fff;
  width: 100%;
  margin: 0;
  padding: 0;
}

.outer-wrapper {
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
}

// .outer-wrapper-fix { /* fix for gap above nav */
// 	margin-bottom: -5px;
// }

@media only screen and (max-width: 690px) {
  .outer-wrapper-fix { /* fix for gap above nav */
    margin-bottom: 0;
  }

  .outer-wrapper-fix-mobile { /* fix for gap above nav */
    margin-bottom: -5px;
  }
}

.grey-bg {
  padding-bottom: 95px;
  background-color: #f2f2f2;
}

/* @-moz-document url-prefix() { /* Firefox fix for homepage grey bg positioning */
.grey-bg {
  margin-top: -34px;
}

/* } */

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .grey-bg {
    padding-bottom: 65px;
  }
}

@media only screen and (max-width: 690px) {
  .grey-bg {
    margin-top: 0;
    padding-bottom: 0;
    background-color: #fff;
  }

  /* @-moz-document url-prefix() { /* Firefox fix for homepage grey bg positioning */
  .grey-bg {
    margin-top: 0;
    padding-bottom: 0;
    background-color: #fff;
  }

  /* } */
}

.grey-bg-no-pad {
  background-color: #f2f2f2;
  padding: 0;
}

.mid-grey-bg-no-pad {
  background-color: #424242;
  padding: 0;
}

.dark-grey-bg-no-pad {
  background-color: #2d2d2d;
  padding: 0;
}

.inner-wrapper {
  width: 960px;
  margin: 0 auto;
  padding: 0;
}

@media only screen and (max-width: 1000px) {
  .inner-wrapper {
    width: 80%;
    margin: 0 10%;
    padding: 0;
  }
}

@media only screen and (min-width: 1001px) {
  #mobile-header {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  #mobile-header {
    display: block;
    margin: 0;
    padding: 5px 0;
    border-bottom: 2px solid #ccc;
  }

  .mobile-header-left {
    display: inline-block;
    float: left;
    text-align: left;
  }

  .mobile-header-right {
    display: inline-block;
    float: right;
    text-align: right;
  }

  .mobile-header-left p,
  .mobile-header-right p {
    margin: 0;
    padding: 0;
  }

  .mobile-header-left p a,
  .mobile-header-right p a {
    font-size: 12px;
    line-height: 16px;
    color: #989898;
    text-decoration: none;
  }

  .mobile-header-left p a:hover,
  .mobile-header-right p a:hover {
    color: #093;
    text-decoration: none;
  }
}

.header-border {
  padding-bottom: 10px;
  border-bottom: 2px solid #ccc;
}

@media only screen and (max-width: 1000px) {
  .header-border {
    border-bottom: 0;
  }
}

#header {
  width: 960px;
  height: auto;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
}

@media only screen and (max-width: 1000px) {
  #header {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
  }
}

#header-logo {
  width: 240px;
  height: 104px;
  float: left;
  margin: 10px 0 0 0;
}

@media only screen and (max-width: 1000px) {
  #header-logo {
    width: 200px;
    height: 87px;
    float: none;
    margin: 10px auto 0 auto;
  }
}

h1#site-title,
div#site-title {
  width: 240px;
  height: 104px;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
}

@media only screen and (max-width: 1000px) {
  h1#site-title,
  div#site-title {
    width: 200px;
    height: 87px;
  }
}

h1#site-title a,
div#site-title a {
  display: block;
  float: left;
  width: 240px;
  height: 104px;
  text-indent: -10000px;
  background-color: transparent;
  background: url("images/Wagner-Renewables-logo.png") no-repeat;
}

@media only screen and (max-width: 1000px) {
  h1#site-title a,
  div#site-title a {
    float: none;
    width: 200px;
    height: 87px;
    background: url("images/Wagner-Renewables-logo-mobile.png") no-repeat;
  }
}

.header-contacts {
  display: inline-block;
  float: right;
  text-align: left;
}

@media only screen and (max-width: 1000px) {
  .header-contacts {
    display: none;
  }
}

.header-contacts .contact {
  display: inline-block;
  margin: 50px 30px 0 0;
}

.header-contacts .location {
  display: inline-block;
  margin: 50px 30px 0 0;
}

.header-contacts .hours {
  display: inline-block;
  margin: 50px 0 0 0;
}

.header-contacts .icon-block {
  display: block;
  float: left;
}

.header-contacts .icon-block img {
  display: block;
  clear: both;
  margin-right: 10px;
}

.header-contacts .text-block {
  display: block;
  float: left;
}

.header-contacts .text-block p.line-1,
.header-contacts .text-block p.line-1 a {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  color: #424242;
  text-decoration: none;
}

.header-contacts .text-block p.line-1 a:hover {
  color: #093;
  text-decoration: none;
}

.header-contacts .text-block p.line-2,
.header-contacts .text-block p.line-2 a {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  color: #989898;
  text-decoration: none;
}

.header-contacts .text-block p.line-2 a:hover {
  color: #093;
  text-decoration: none;
}

#nav {
  display: inline-block;
  float: left;
}

@media only screen and (min-width: 1001px) and (-ms-high-contrast: none), (-ms-high-contrast: active) { /* IE (desktop) fix to nav container height */
  #nav {
    padding-bottom: 1px;
  }
}

#nav ul {
  font-family: 'Montserrat', sans-serif;
}

#mega-menu-wrap-top-nav #mega-menu-top-nav > li#mega-menu-item-12 > a.mega-menu-link { /* Left-align Home menu item */
  padding-left: 0 !important;
}

#other-nav {
  display: inline-block;
  float: right;
}

@media only screen and (max-width: 1000px) {
  #other-nav {
    display: none;
  }
}

#social-media-nav {
  display: inline-block;
  float: left;
}

#social-media-nav a {
  display: block;
  float: left;
  line-height: 22px;
  height: 24px;
  position: relative;
  background-position: center top;
  padding: 10px 20px;
  border-left: 2px solid #ccc;
  color: #ccc;
}

#social-media-nav a i {
  width: auto;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  background-color: transparent;
  color: #ccc;
}

#social-media-nav a:hover i {
  color: #093;
}

[class*="fa-"] {
  display: inline-block;
  top: -2px;
  word-spacing: 1px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
}

.fa {
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#search-nav {
  display: inline-block;
  float: left;
  background-color: #16a542;
}

#search-nav input.search-field {
  width: 115px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 14px 16px 13px 16px;
  background-color: #16a542;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

@media only screen and (min-width: 1001px) and (-ms-high-contrast: none), (-ms-high-contrast: active) { /* IE (desktop) fix to nav container height */
  #search-nav input.search-field {
    padding-bottom: 14px;
  }
}

#search-nav input.search-submit,
#search-nav input[type="submit"] {
  display: inline-block;
  width: 30px;
  height: auto;
  margin: 0;
  padding: 0 0 7px 0;
  background: url("assets/images/search-icon.png") 0 8px no-repeat;
  border: 0;
}

@-moz-document url-prefix() { /* Firefox fix for icon positioning */
  #search-nav input.search-submit,
  #search-nav input[type="submit"] {
    background: url("images/search-icon.png") 0 3px no-repeat;
  }
}

@media only screen and (min-width: 1001px) and (-ms-high-contrast: none), (-ms-high-contrast: active) { /* IE (desktop) fix to nav container height */
  #search-nav input.search-submit,
  #search-nav input[type="submit"] {
    background: url("images/search-icon.png") 0 3px no-repeat;
  }
}

#search-nav input.search-field::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff !important;
}

#search-nav input.search-field::-moz-placeholder { /* Firefox 19+ */
  color: #fff !important;
}

#search-nav input.search-field:-ms-input-placeholder { /* IE 10+ */
  color: #fff !important;
}

#search-nav input.search-field:-moz-placeholder { /* Firefox 18- */
  color: #fff !important;
}

.homepage-shortcuts {
  position: relative;
  display: inline-block;
  width: 960px;
  height: auto;
  margin-top: -45px;
  padding: 40px 0;
  background-color: #fff;
  z-index: 21;
}

@media only screen and (max-width: 1000px) {
  .homepage-shortcuts {
    width: 100%;
    height: auto;
    margin-top: 0;
    padding: 35px 0 45px 0;
  }
}

.homepage-shortcut-entry {
  display: inline-block;
  float: left;
  width: 216px;
  height: auto;
  margin: 0;
  padding: 20px 52px;
  border-right: 0;
}

@media only screen and (max-width: 1000px) {
  .homepage-shortcut-entry {
    float: none;
    width: 74%;
    margin: 0;
    padding: 20px 13%;
    text-align: center;
  }
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .homepage-shortcut-entry {
    float: none;
    width: 54%;
    margin: 0;
    padding: 20px 23%;
    text-align: center;
  }
}

@media only screen and (min-width: 1001px) {
  .homepage-shortcut-entry:nth-child(3n+2) {
    width: 212px;
    border-left: 2px solid #ccc;
    border-right: 2px solid #ccc;
  }

  .homepage-shortcut-entry:nth-child(3n) {
    float: none;
    clear: both;
  }
}

.homepage-shortcut-entry img {
  display: block;
  clear: both;
}

.homepage-shortcut-entry img.icon {
  width: auto;
  height: 48px;
}

@media only screen and (max-width: 1000px) {
  .homepage-shortcut-entry img,
  .homepage-shortcut-entry img.icon {
    margin: 0 auto;
  }
}

.homepage-shortcut-entry h3 {
  margin: 10px 0 0 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 1000px) {
  .homepage-shortcut-entry h3 {
    margin-top: 5px;
  }
}

.yellow-bar {
  display: block;
  width: 29px;
  height: 3px;
  margin: 3px 0 5px 0;
  background-color: #ffcb01;
}

@media only screen and (max-width: 1000px) {
  .yellow-bar {
    margin: 3px auto 5px auto;
  }
}

.small-button,
.medium-button {
  display: inline-block;
  float: left;
}

@media only screen and (max-width: 1000px) {
  .homepage-shortcut-entry .small-button {
    float: none;
    clear: both;
    margin-left: auto;
    margin-right: auto;
  }
}

.small-button-left {
  display: inline-block;
  float: left;
  width: 70px;
  height: 9px;
  padding: 12px 15px 15px 15px;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  line-height: 13px;
  font-weight: 300;
  letter-spacing: 0.25px;
  color: #fff;
  text-transform: uppercase;
  background: #16a542;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.small-button-right {
  display: inline-block;
  float: left;
  width: 7px;
  height: 9px;
  padding: 12px 15px 15px 15px;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  line-height: 13px;
  font-weight: 300;
  letter-spacing: 0.25px;
  color: #fff;
  background: #093;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.medium-button-left {
  display: inline-block;
  float: left;
  width: 88px;
  height: 11px;
  padding: 16px 18px 18px 18px;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  line-height: 13px;
  font-weight: 300;
  letter-spacing: 0.25px;
  color: #fff;
  text-transform: uppercase;
  background: #16a542;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.medium-button-right {
  display: inline-block;
  float: left;
  width: 9px;
  height: 11px;
  padding: 16px 18px 18px 18px;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  line-height: 13px;
  font-weight: 300;
  letter-spacing: 0.25px;
  color: #fff;
  background: #093;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.small-button:hover .small-button-left,
.medium-button:hover .medium-button-left {
  background: #069532;
  cursor: pointer;
}

.small-button:hover .small-button-right,
.medium-button:hover .medium-button-right {
  background: #01812c;
  cursor: pointer;
}

.homepage-shortcut-entry p {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  color: #989898;
}

.homepage-shortcut-entry img.button {
  display: inline-block;
  clear: both;
  border: 0;
}

.homepage-shortcut-entry img.button:hover {
  opacity: 0.9;
}

.homepage-product-showcase {
  background: url("images/grey-green-bg-full.jpg") center center no-repeat;
}

@media only screen and (max-width: 690px) {
  .homepage-product-showcase .inner-wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}

@media only screen and (max-width: 690px) {
  .homepage-product-showcase {
    background: none;
  }
}

.homepage-product-showcase-left-container,
.homepage-product-showcase-right-container {
  display: inline-block;
  float: left;
  width: 50%;
}

.homepage-product-showcase-right-container {
  text-align: right;
}

@media only screen and (max-width: 690px) {
  .homepage-product-showcase-left-container,
  .homepage-product-showcase-right-container {
    display: block;
    float: none;
    width: 100%;
  }
}

.homepage-product-showcase-left {
  padding: 110px 60px 110px 0;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .homepage-product-showcase-left {
    padding: 18% 60px 18% 0;
  }
}

@media only screen and (max-width: 690px) {
  .homepage-product-showcase-left {
    padding: 50px;
    background-color: #424242;
    text-align: center;
  }
}

.homepage-product-showcase-left h2 {
  margin: 0 0 15px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.5px;
}

.homepage-product-showcase-left h4 {
  margin: 0 0 5px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: #fff;
  letter-spacing: 0.5px;
}

.homepage-product-showcase-left p {
  margin: 12px 0 18px 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: #fff;
}

@media only screen and (max-width: 690px) {
  .homepage-product-showcase-left .medium-button {
    clear: both;
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

.homepage-product-showcase-right {
  display: inline-block;
  text-align: right;
  padding: 50px 0 20px 0;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .homepage-product-showcase-right {
    padding: 18% 0 18% 0;
  }
}

@media only screen and (max-width: 690px) {
  .homepage-product-showcase-right {
    display: inline-block;
    width: 100%;
    padding: 25px 0 10px 0;
    text-align: center;
    background: url("images/grey-green-bg-mobile.png") center center no-repeat;
    background-size: cover;
  }
}

.homepage-product-showcase-right img {
  display: block;
  float: right;
  padding-right: 5%;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .homepage-product-showcase-right img {
    padding-right: 2%;
  }
}

@media only screen and (max-width: 690px) {
  .homepage-product-showcase-right img {
    display: block;
    float: none;
    padding-right: 0;
    margin: 0 auto;
  }
}

.cta-strip-left {
  display: inline-block;
  float: left;
  margin: 0;
  padding: 56px 0 51px 0;
  vertical-align: middle;
}

@media only screen and (max-width: 1000px) {
  .cta-strip-left {
    display: block;
    float: none;
    width: 80%;
    margin: 0;
    padding: 40px 10% 10px 10%;
    text-align: center;
  }
}

.cta-strip-left p {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #424242;
}

.cta-strip-right {
  display: inline-block;
  float: right;
  margin: 0;
  padding: 45px 0 40px 0;
  vertical-align: middle;
}

@media only screen and (max-width: 1000px) {
  .cta-strip-right {
    display: block;
    float: none;
    width: 80%;
    margin: 0;
    padding: 10px 10% 40px 10%;
    text-align: center;
  }

  .cta-strip-right .medium-button {
    float: none;
  }
}

.cta-strip-right img.button {
  display: inline-block;
  clear: both;
  border: 0;
}

.cta-strip-right img.button:hover {
  opacity: 0.9;
}

.homepage-client-logos {
  display: inline-block;
  padding: 45px 0;
  display: flex;
  justify-content: space-between;
}

.homepage-client-logos img {
  display: inline-block;
  float: left;
  width: auto;
  height: 52px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.5;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .homepage-client-logos a:nth-child(n+5) {
    display: none;
  }

  .homepage-client-logos img {
    height: 30px;
    width: auto;
  }
}

@media only screen and (max-width: 690px) {
  .homepage-client-logos a:nth-child(n+4) {
    display: none;
  }

  .homepage-client-logos img {
    height: 22px;
    width: auto;
  }
}

.homepage-client-logos img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

.footer-left,
.footer-middle,
.footer-right {
  display: inline-block;
  float: left;
  width: 33.33%;
  margin: 60px 0;
}

@media only screen and (max-width: 1000px) {
  .footer-left {
    display: block;
    float: none;
    width: 100%;
    margin: 40px 0 0;
  }

  .footer-middle {
    display: none;
    margin: 0;
  }

  .footer-right {
    display: block;
    float: none;
    width: 100%;
    margin: 40px 0 40px 0;
  }
}

@media only screen and (max-width: 396px) {
  .footer-right img {
    width: auto;
    height: 44px;
  }
}

.footer-left h4,
.footer-middle h4 {
  margin: 0 0 18px 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.5px;
}

.footer-left p,
.footer-middle p,
.footer-middle ul#menu-footer-nav li {
  margin: 0 0 10px 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  color: #fff;
}

.footer-left p a,
.footer-middle p a,
.footer-middle ul#menu-footer-nav li a {
  color: #fff;
  text-decoration: none;
}

.footer-left p a:hover,
.footer-middle p a:hover,
.footer-middle ul#menu-footer-nav li a:hover {
  color: #093;
  text-decoration: none;
}

.footer-left #social-media-nav-footer {
  display: inline-block;
  float: left;
  margin-top: 12px;
}

.footer-left #social-media-nav-footer a {
  display: inline-block;
  float: left;
  margin-right: 5px;
  position: relative;
  background-position: center center;
  color: #ccc;
}

.footer-left #social-media-nav-footer a:last-child {
  margin-right: 0;
}

.footer-left #social-media-nav-footer a i {
  display: inline-block;
  width: 14px;
  height: 14px;
  padding: 5px;
  line-height: 14px;
  font-size: 12px;
  background-color: #686868;
  color: #ccc;
}

.footer-left #social-media-nav-footer a:hover i {
  background-color: #093;
  color: #fff;
}

.footer-middle ul#menu-footer-nav {
  margin-left: 0;
  list-style-type: none;
}

.footer-right {
  position: relative;
  min-height: 179px;
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
  .footer-right {
    min-height: 110px;
    padding-top: 0;
  }
}

@media only screen and (min-width: 370px) and (max-width: 770px) {
  .footer-right {
    clear: both;
    float: none;
    min-height: 150px;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 369px) {
  .footer-right {
    clear: both;
    float: none;
    min-height: 250px;
    padding-top: 40px;
  }
}

.accred-container {
  position: absolute;
  bottom: 0;
}

.accred-container .accred-row1,
.accred-container .accred-row2,
.accred-container .accred-row3 {
  display: inline-block;
  float: none;
  clear: both;
}

.accred-container .accred-row1 a img,
.accred-container .accred-row2 a img,
.accred-container .accred-row3 a img {
  display: block;
  float: left;
  margin-right: 20px;
}

.accred-container .accred-row2 a img {
  margin-right: 28px;
}

.accred-container .accred-row1 a:last-child img,
.accred-container .accred-row2 a:last-child img,
.accred-container .accred-row3 a:last-child img {
  margin-right: 0;
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
  .accred-container .accred-row1 a:last-child img {
    margin-right: 20px;
  }
}

.accred-container .accred-row2 a:last-child img {
  margin-top: -10px; /* Move Checkatrade logo up to close gap above */
}

@media only screen and (min-width: 771px) and (max-width: 1000px) {
  .accred-container .accred-row2 a:last-child img {
    margin-top: -5px; /* Move Checkatrade logo up to close gap above */
  }
}

.accred-container .accred-row1 a img:hover,
.accred-container .accred-row2 a img:hover,
.accred-container .accred-row3 a img:hover {
  opacity: 0.6;
}

.copyright-left,
.copyright-right {
  display: block;
  width: 50%;
  background-color: #2d2d2d;
}

.copyright-left {
  float: left;
  text-align: left;
}

.copyright-right {
  float: right;
  text-align: right;
}

@media only screen and (max-width: 690px) {
  .copyright-left,
  .copyright-right {
    display: block;
    float: none;
    clear: both;
    width: 100%;
    text-align: left;
  }

  .copyright-left {
    padding-top: 15px;
  }

  .copyright-right {
    padding-bottom: 15px;
  }
}

.copyright-left p,
.copyright-right p {
  margin: 0;
  padding: 18px 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: #fff;
}

@media only screen and (max-width: 690px) {
  .copyright-left p,
  .copyright-right p {
    padding: 0;
  }
}

.copyright-left p a,
.copyright-right p a {
  color: #fff;
  text-decoration: none;
}

.copyright-left p a:hover,
.copyright-right p a:hover {
  color: #093;
  text-decoration: none;
}

.sitewide-header {
  display: block;
  width: 100%;
  height: 104px;
  margin-bottom: 70px;
  background: url("images/solar-panels-header.jpg") center center no-repeat;
}

.halfpad {
  margin-bottom: 35px;
}

.sitewide-header-grey {
  display: block;
  width: 100%;
  height: 104px;
  margin-bottom: 0;
  padding-bottom: 50px;
  background: #f2f2f2 url("images/solar-panels-header.jpg") top center no-repeat;
}

.margin-fix {
  margin-bottom: 0;
}

.sitewide-header .page-header,
.sitewide-header-grey .page-header {
  padding: 20px 0;
}

.sitewide-header .page-header h1,
.sitewide-header-grey .page-header h1 {
  margin: 0 0 10px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  line-height: 36px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 690px) {
  .sitewide-header .page-header h1,
  .sitewide-header-grey .page-header h1 {
    margin: 5px 0;
    font-size: 22px;
    line-height: 28px;
  }
}

.sitewide-header .page-header p,
.sitewide-header-grey .page-header p {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #fff;
}

.sitewide-header .page-header p a,
.sitewide-header-grey .page-header p a {
  color: #fff;
  text-decoration: none;
}

.sitewide-header .page-header p a:hover,
.sitewide-header-grey .page-header p a:hover {
  color: #fff;
  text-decoration: underline;
}

.page-header-map {
  display: block;
  width: 100%;
  height: 240px;
  margin-bottom: 70px;
  text-align: center;
  background-image: url("images/wr-map-image.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  img {
    display: block;
    width: 100%;
    object-fit: contain;
  }
}

.products-left-odd {
  display: block;
  float: left;
  width: 50%;
  min-height: 370px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 1000px) {
  .products-left-odd {
    display: none;
  }
}

.products-left-odd-image {
  height: 80%;
  margin-top: 10%;
  margin-bottom: 10%;
  padding: 0;
  text-align: center;
}

.products-left-odd-image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.products-right-odd {
  display: table;
  float: left;
  width: 50%;
  min-height: 370px;
  background-color: #424242;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .products-right-odd {
    display: block;
    height: 370px;
  }
}

@media only screen and (max-width: 1000px) {
  .products-right-odd {
    display: block;
    float: none;
    clear: both;
    width: 100%;
    min-height: 100px;
  }
}

.products-right-container-odd {
  display: table-cell;
  width: 430px;
  padding-left: 50px;
  vertical-align: middle;
}

@media only screen and (min-width: 961px) and (max-width: 1000px) {
  .products-right-container-odd {
    display: inline-block;
    width: 80%;
    padding: 3% 10%;
  }
}

@media only screen and (max-width: 1000px) {
  .products-right-container-odd {
    display: inline-block;
    width: 80%;
    padding: 5% 10%;
  }
}

.products-right-odd .products-right-container-odd h3 {
  width: 430px;
  margin: 0 0 12px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.5px;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .products-right-odd .products-right-container-odd h3 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 1000px) {
  .products-right-odd .products-right-container-odd h3 {
    float: none;
    clear: both;
    width: 100%;
    margin: 0 0 10px 0;
    font-size: 22px;
    line-height: 28px;
  }
}

.products-right-odd .products-right-container-odd p {
  width: 430px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #fff;
}

@media only screen and (max-width: 1000px) {
  .products-right-odd .products-right-container-odd p {
    float: none;
    clear: both;
    width: 100%;
  }
}

.products-right-odd .products-right-container-odd a {
  color: #093;
  text-decoration: none;
}

.products-right-odd .products-right-container-odd a:hover {
  color: #093;
  text-decoration: underline;
}

.products-right-odd .products-button {
  margin: 20px 0 0 0;
}

.products-left-even {
  display: table;
  float: left;
  width: 50%;
  min-height: 370px;
  background-color: #424242;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .products-left-even {
    display: block;
    height: 370px;
  }
}

@media only screen and (max-width: 1000px) {
  .products-left-even {
    display: block;
    float: none;
    clear: both;
    width: 100%;
    min-height: 100px;
  }
}

.products-left-container-even {
  display: table-cell;
  width: 430px;
  padding-right: 50px;
  vertical-align: middle;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .products-left-even .products-left-container-even h3 {
    padding-left: 80px;
  }
}

@media only screen and (max-width: 1000px) {
  .products-left-container-even {
    display: inline-block;
    width: 80%;
    padding: 5% 10%;
  }
}

.products-left-even .products-left-container-even h3 {
  float: right;
  width: 430px;
  margin: 0 0 12px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0.5px;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .products-left-even .products-left-container-even h3 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 1000px) {
  .products-left-even .products-left-container-even h3 {
    float: none;
    clear: both;
    width: 100%;
    margin: 0 0 10px 0;
    font-size: 22px;
    line-height: 28px;
  }
}

.products-left-even .products-left-container-even p {
  float: right;
  clear: both;
  width: 430px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #fff;
}

@media only screen and (max-width: 1000px) {
  .products-left-even .products-left-container-even p {
    float: none;
    clear: both;
    width: 100%;
  }
}

.products-left-even .products-left-container-even a {
  color: #093;
  text-decoration: none;
}

.products-left-even .products-left-container-even a:hover {
  color: #093;
  text-decoration: underline;
}

.products-left-even .products-button {
  float: right;
  clear: both;
  width: 430px;
  margin: 10px 0 0 0;
}

@media only screen and (max-width: 1000px) {
  .products-left-even .products-button {
    float: left;
    width: 100%;
    padding-top: 10px;
  }
}

.products-right-even {
  display: block;
  float: left;
  width: 50%;
  min-height: 370px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 1000px) {
  .products-right-even {
    display: none;
  }
}

.products-right-even-image {
  height: 80%;
  margin-top: 10%;
  margin-bottom: 10%;
  padding: 0;
  text-align: center;
}

.products-right-even-image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.anchor-buttons {
  margin: 20px 0;
  padding: 0;
}

.anchor-buttons .medium-button {
  margin: 0;
}

.anchor-buttons .medium-button:first-child {
  margin-right: 20px;
}

@media only screen and (max-width: 690px) {
  .anchor-buttons {
    margin-top: 25px;
    margin-bottom: 0;
  }

  .anchor-buttons .medium-button {
    clear: both;
    float: none;
    margin: 0;
  }
}

.product-detail-right .product-detail-right-container .products-button .medium-button {
  margin: 0;
}

.product-detail-right .product-detail-right-container .products-button .medium-button:first-child {
  margin-top: 20px;
  margin-right: 20px;
}

@media only screen and (max-width: 690px) {
  .product-detail-right .product-detail-right-container .products-button .medium-button:first-child {
    clear: both;
  }
}

.anchor-buttons .medium-button-left {
  width: 140px;
  border-radius: 5px;
  text-align: center;
}

.anchor-buttons .medium-button:first-child {
  margin-right: 20px;
}

@media only screen and (max-width: 690px) {
  .anchor-buttons .medium-button:first-child {
    clear: both;
    margin-bottom: 20px;
  }
}

.products-grid-container {
  display: inline-block;
  width: 960px;
  margin-top: 50px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 1000px) {
  .products-grid-container {
    width: 100%;
  }
}

.products-grid-container h3 {
  margin: 0 0 20px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0.5px;
}

.products-grid-container .product {
  display: inline-block;
  float: left;
  width: 156px;
  margin-right: 45px;
  margin-bottom: 40px;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .products-grid-container .product {
    width: 28%;
    margin-right: 8%;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 690px) {
  .products-grid-container .product {
    width: 45%;
    margin-right: 10%;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 1001px) {
  .products-grid-container .product:nth-child(5n+1) { /* every 5th product */
    float: none;
    clear: both;
    margin-right: 0;
  }
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .products-grid-container .product:nth-child(3n+1) { /* every 3rd product */
    margin-right: 0;
    float: none;
    clear: both;
  }
}

@media only screen and (max-width: 690px) {
  .products-grid-container .product:nth-child(2n+1) { /* every 2n product */
    margin-right: 0;
    float: none;
    clear: both;
  }
}

.products-grid-container .product .img-bg {
  display: block;
  position: relative;
  padding: 20px;
  background: url("images/product-section-background.jpg") center center no-repeat;
  background-size: cover;
  border-bottom: 3px solid #ffcb01;
}

@media only screen and (min-width: 1001px) {
  .products-grid-container .product .img-bg {
    width: 116px;
    height: 80px;
  }
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .products-grid-container .product .img-bg {
    min-height: 100px;
    padding: 15%;
  }
}

@media only screen and (max-width: 690px) {
  .products-grid-container .product .img-bg {
    min-height: 80px;
    padding: 15%;
  }
}

.products-grid-container .product .img-bg img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 80%;
  max-height: 80%;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .products-grid-container .product .img-bg img {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 80%;
    max-height: 80%;
  }
}

.products-grid-container .product h3 {
  width: 100%;
  margin: 6px 0 0 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0.25px;
}

.products-grid-container .product h4 {
  width: 100%;
  margin: 0 0 8px 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: #989898;
  letter-spacing: 0;
}

.products-grid-container .product .small-button-left {
  height: 10px;
  padding: 13px 15px 13px 15px;
  font-size: 11px;
  line-height: 11px;
}

@media only screen and (max-width: 690px) {
  .products-grid-container .product .small-button-left {
    padding: 12px 12px 13px 12px;
  }

  .products-grid-container .product .small-button-right {
    padding: 12px 12px 14px 12px;
  }
}

.product-detail-left {
  display: block;
  float: left;
  width: 470px;
  height: 445px;
  margin-right: 10px;
  margin-bottom: 60px;
  background: url("images/product-section-background.jpg") center center no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.product-detail-left.image-right,
.product-detail-right.text-right {
  float: right !important;

  @media only screen and (max-width: 767px) {
    margin-right: 0 !important;
  }
}

.product-detail-right.text-right {
  margin-left: 0 !important;
  margin-right: 45px !important;

  @media only screen and (max-width: 767px) {
    margin-left: 45px !important;
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 1000px) {
  .product-detail-left {
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-bottom: 0;
  }
}

.product-detail-left .img-bg {
  display: block;
  position: relative;
  padding: 20px;
  background: url("images/product-section-background.jpg") center center no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 1001px) {
  .product-detail-left .img-bg {
    width: 430px;
    height: 430px;
  }
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .product-detail-left .img-bg {
    min-height: 100px;
    padding: 15%;
  }
}

@media only screen and (max-width: 690px) {
  .product-detail-left .img-bg {
    min-height: 80px;
    padding: 15%;
  }
}

.product-detail-left .img-bg img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 80%;
  max-height: 80%;
}

@media only screen and (max-width: 1000px) {
  .product-detail-left .img-bg img {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 80%;
    max-height: 80%;
  }
}

.product-detail-right {
  display: table;
  float: left;
  width: 435px;
  min-height: 445px;
  margin-bottom: 35px;
  margin-left: 45px;
  background-color: #fff;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment */
  .product-detail-right {
    display: block;
    height: auto;
  }
}

@media only screen and (max-width: 1000px) {
  .product-detail-right {
    display: block;
    float: none;
    clear: both;
    width: 100%;
    margin-left: 0;
    min-height: 100px;
  }
}

.product-detail-right-container {
  display: table-cell;
  width: 50%;
  vertical-align: middle;
}

@media only screen and (min-width: 961px) and (max-width: 1000px) {
  .product-detail-right-container {
    display: inline-block;
    width: 80%;
    padding: 3% 10%;
  }
}

@media only screen and (max-width: 960px) {
  .product-detail-right-container {
    display: inline-block;
    width: 100%;
    padding: 5% 0;
  }
}

.product-detail-right .product-detail-right-container h3 {
  width: 430px;
  margin: 0 0 12px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0.5px;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .product-detail-right .product-detail-right-container h3 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 1000px) {
  .product-detail-right .product-detail-right-container h3 {
    float: none;
    clear: both;
    width: 100%;
    margin: 0 0 10px 0;
    font-size: 22px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 1000px) {
  .product-detail-right .product-detail-right-container .yellow-bar {
    margin: 3px 0 5px 0;
  }
}

.product-detail-right .product-detail-right-container p {
  width: 430px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #989898;
}

@media only screen and (max-width: 1000px) {
  .product-detail-right .product-detail-right-container p {
    float: none;
    clear: both;
    width: 100%;
  }
}

.product-detail-right .product-detail-right-container ul li {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #989898;
}

.product-detail-right .product-detail-right-container a {
  color: #093;
  text-decoration: none;
}

.product-detail-right .product-detail-right-container a:hover {
  color: #093;
  text-decoration: underline;
}

.product-detail-right .product-detail-right-container .products-button .medium-button {
  margin: 0;
}

.product-detail-right .product-detail-right-container .products-button .medium-button:first-child {
  margin-top: 20px;
  margin-right: 20px;
}

@media only screen and (max-width: 690px) {
  .product-detail-right .product-detail-right-container .products-button .medium-button:first-child {
    clear: both;
  }
}

.about-us-intro-left { /* NB about-us classes also used on Services pages */
  display: inline-block;
  float: left;
  width: 420px;
  min-height: 299px;
  margin-bottom: 75px;
  padding-right: 60px;
}

@media only screen and (max-width: 1000px) {
  .about-us-intro-left {
    display: inline-block;
    float: left;
    width: 100%;
    min-height: 100px;
    margin-bottom: 30px;
    padding-right: 0;
  }
}

.about-us-intro-left h2 {
  margin: 0 0 10px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  color: #424242;
  letter-spacing: 0.5px;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .about-us-intro-left h2 {
    font-size: 26px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 690px) {
  .about-us-intro-left h2 {
    font-size: 24px;
    line-height: 30px;
  }
}

.about-us-intro-left .yellow-bar {
  margin: 12px 0 15px 0;
}

@media only screen and (max-width: 1000px) {
  .about-us-intro-left .yellow-bar {
    display: inline-block;
  }
}

.about-us-intro-left p {
  margin: 0 0 12px 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #989898;
}

.about-us-intro-left p a {
  color: #093;
  text-decoration: none;
}

.about-us-intro-left p a:hover {
  color: #093;
  text-decoration: underline;
}

.about-us-intro-left .intro-buttons {
  margin: 20px 0 0 0;
  padding: 0;
}

.about-us-intro-left .intro-buttons .medium-button:first-child {
  margin-right: 20px;
}

@media only screen and (max-width: 690px) {
  .about-us-intro-left .intro-buttons .medium-button:first-child {
    clear: both;
    margin-bottom: 20px;
  }
}

.about-us-intro-right {
  display: inline-block;
  float: left;
  width: 480px;
  height: 299px;
  margin-bottom: 75px;
}

@media only screen and (max-width: 1000px) {
  .about-us-intro-right {
    display: block;
    float: none;
    clear: both;
    width: 100%;
    height: auto;
    margin-bottom: 50px;
  }
}

.about-us-intro-right img {
  margin-top: 10px;
  width: 480px;
  height: 299px;
}

@media only screen and (max-width: 1000px) {
  .about-us-intro-right img {
    margin-top: 10px;
    width: 100%;
    height: auto;
  }
}

.about-us-services-left-odd {
  display: block;
  float: left;
  width: 50%;
  min-height: 370px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 1000px) {
  .about-us-services-left-odd {
    display: none;
  }
}

.about-us-services-right-odd {
  display: table;
  float: left;
  width: 50%;
  min-height: 370px;
  background-color: #f2f2f2;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .about-us-services-right-odd {
    display: block;
    height: 370px;
  }
}

@media only screen and (max-width: 1000px) {
  .about-us-services-right-odd {
    display: block;
    float: none;
    clear: both;
    width: 100%;
    min-height: 100px;
  }
}

.about-us-services-right-container-odd {
  display: table-cell;
  width: 430px;
  padding-left: 50px;
  vertical-align: middle;
}

@media only screen and (max-width: 1000px) {
  .about-us-services-right-container-odd {
    display: inline-block;
    width: 80%;
    padding: 5% 10%;
  }
}

.about-us-services-right-odd .about-us-services-right-container-odd h3 {
  width: 430px;
  margin: 10px 0 0 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0.5px;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .about-us-services-right-odd .about-us-services-right-container-odd h3 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 1000px) {
  .about-us-services-right-odd .about-us-services-right-container-odd h3 {
    float: none;
    clear: both;
    width: 100%;
    margin: 0;
  }
}

.about-us-services-right-odd .about-us-services-right-container-odd .yellow-bar {
  margin: 15px 0;
}

.about-us-services-right-odd .about-us-services-right-container-odd p {
  width: 430px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #989898;
}

@media only screen and (max-width: 1000px) {
  .about-us-services-right-odd .about-us-services-right-container-odd p {
    float: none;
    clear: both;
    width: 100%;
  }
}

.about-us-services-right-odd .about-us-services-right-container-odd a {
  color: #093;
  text-decoration: none;
}

.about-us-services-right-odd .about-us-services-right-container-odd a:hover {
  color: #093;
  text-decoration: underline;
}

.about-us-services-left-even {
  display: table;
  float: left;
  width: 50%;
  min-height: 370px;
  background-color: #f2f2f2;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .about-us-services-left-even {
    display: block;
    height: 370px;
  }
}

@media only screen and (max-width: 1000px) {
  .about-us-services-left-even {
    display: block;
    float: none;
    clear: both;
    width: 100%;
    min-height: 100px;
  }
}

.about-us-services-left-container-even {
  display: table-cell;
  width: 430px;
  padding-right: 50px;
  vertical-align: middle;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .about-us-services-left-even .about-us-services-left-container-even h3 {
    padding-left: 80px;
  }
}

@media only screen and (max-width: 1000px) {
  .about-us-services-left-container-even {
    display: inline-block;
    width: 80%;
    padding: 5% 10%;
  }
}

.about-us-services-left-even .about-us-services-left-container-even h3 {
  float: right;
  width: 430px;
  margin: 10px 0 0 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0.5px;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .about-us-services-left-even .about-us-services-left-container-even h3 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 1000px) {
  .about-us-services-left-even .about-us-services-left-container-even h3 {
    float: none;
    clear: both;
    width: 100%;
    margin: 0;
  }
}

.about-us-services-left-even .about-us-services-left-container-even .yellow-bar-container {
  float: right;
  width: 29px;
  padding-right: 401px; /* maintain 29px width */
}

@media only screen and (max-width: 1000px) {
  .about-us-services-left-even .about-us-services-left-container-even .yellow-bar-container {
    float: none;
    clear: both;
    width: 29px;
    padding-right: 0;
  }
}

.about-us-services-left-even .about-us-services-left-container-even .yellow-bar {
  margin: 15px 0;
}

.about-us-services-left-even .about-us-services-left-container-even p {
  float: right;
  clear: both;
  width: 430px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #989898;
}

@media only screen and (max-width: 1000px) {
  .about-us-services-left-even .about-us-services-left-container-even p {
    float: none;
    clear: both;
    width: 100%;
  }
}

.about-us-services-left-even .about-us-services-left-container-even a {
  color: #093;
  text-decoration: none;
}

.about-us-services-left-even .about-us-services-left-container-even a:hover {
  color: #093;
  text-decoration: underline;
}

.about-us-services-right-even {
  display: block;
  float: left;
  width: 50%;
  min-height: 370px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 1000px) {
  .about-us-services-right-even {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .about-us-services-left-even .about-us-services-left-container-even .yellow-bar-container .yellow-bar,
  .about-us-services-right-odd .about-us-services-right-container-odd .yellow-bar {
    display: inline-block;
  }
}

.blog-listing-item {
  display: inline-block;
  float: left;
  width: 280px;
  margin: 0 60px 50px 0;
}

.blog-listing-item.case-item {
  float: none !important;
  flex: 0 0 280px;
}

@media only screen and (min-width: 1001px) {
  .blog-listing-item:nth-child(3n+3) { /* every 3rd blog post */
    margin-right: 0;
  }

  .blog-listing-item:nth-child(3n+1) {
    clear: left;
  }
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .blog-listing-item {
    width: 45%;
    margin: 0 2.5% 40px 2.5%;
  }

  .blog-listing-item:nth-child(2n+2) { /* every 2nd blog post */
    margin-right: 0;
  }

  .blog-listing-item:nth-child(2n+1) {
    clear: left;
  }
}

@media only screen and (max-width: 691px) {
  .blog-listing-item {
    clear: both;
    float: none;
    width: 100%;
    margin: 0 0 40px 0;
  }
}

@media only screen and (max-width: 481px) {
  .blog-listing-item {
    margin: 0 0 30px 0;
  }
}

.blog-listing-item img {
  display: block;
  clear: both;
  width: 280px;
  height: auto;
  border-bottom: 3px solid #ffcb01;
}

@media only screen and (max-width: 1000px) {
  .blog-listing-item img {
    width: 100%;
  }
}

.blog-listing-item img:hover {
  /* opacity: 0.8; */
  border-bottom: 3px solid #093;
}

.blog-listing-item .blog-listing-item-text-container {
  display: inline-block;
  float: left;
  width: 220px;
  margin: 0;
  padding: 20px 30px;
  background-color: #fff;
}

@media only screen and (max-width: 1000px) {
  .blog-listing-item .blog-listing-item-text-container {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.blog-listing-item .blog-listing-item-text-container .blog-metadata-left,
.blog-listing-item .blog-listing-item-text-container .blog-metadata-right {
  display: inline-block;
  float: left;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #989898;
}

.blog-listing-item .blog-listing-item-text-container .blog-metadata-left {
  width: 45%;
}

.blog-listing-item .blog-listing-item-text-container .blog-metadata-right {
  width: 55%;
}

.blog-listing-item .blog-listing-item-text-container .blog-metadata-left ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.blog-listing-item .blog-listing-item-text-container .blog-metadata-left ul li {
  margin: 0;
  padding: 0;
}

.blog-listing-item .blog-listing-item-text-container .blog-metadata-left ul li a {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #989898;
  text-decoration: none;
}

.blog-listing-item .blog-listing-item-text-container .blog-metadata-left ul li a:hover {
  color: #093;
  text-decoration: none;
}

.blog-listing-item .blog-listing-item-text-container .blog-metadata-right {
  text-align: right;
}

.blog-listing-item .blog-listing-item-text-container h3 {
  margin: 0 0 10px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0.5px;
}

.blog-listing-item .blog-listing-item-text-container h3 a {
  color: #424242;
  text-decoration: none;
}

.blog-listing-item .blog-listing-item-text-container h3 a:hover {
  color: #093;
  text-decoration: none;
}

.blog-listing-item .blog-listing-item-text-container p {
  margin-bottom: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #989898;
}

.blog-detail-container {
  display: inline-block;
  margin-bottom: 40px;
}

.blog-detail-container img {
  width: 740px;
  height: auto;
}

@media only screen and (max-width: 1000px) {
  .blog-detail-container img {
    width: 100%;
  }
}

.blog-detail {
  display: inline-block;
  float: left;
  width: 740px;
  margin-bottom: 10px;
  padding-right: 30px;
}

@media only screen and (max-width: 1000px) {
  .blog-detail {
    display: block;
    float: none;
    clear: both;
    width: 100%;
    padding-right: 0;
  }
}

.blog-detail .blog-detail-metadata-container {
  display: inline-block;
  float: left;
  width: 740px;
  margin: 0;
  padding: 30px 0 0 0;
}

@media only screen and (max-width: 1000px) {
  .blog-detail .blog-detail-metadata-container {
    display: block;
    float: none;
    clear: both;
    width: 100%;
  }
}

.blog-detail .blog-detail-metadata-container .blog-metadata-left,
.blog-detail .blog-detail-metadata-container .blog-metadata-right {
  display: inline-block;
  float: left;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #989898;
}

.blog-detail .blog-detail-metadata-container .blog-metadata-left {
  width: 45%;
}

.blog-detail .blog-detail-metadata-container .blog-metadata-right {
  width: 55%;
}

.blog-detail .blog-detail-metadata-container .blog-metadata-left ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.blog-detail .blog-detail-metadata-container .blog-metadata-left ul li {
  margin: 0;
  padding: 0;
}

.blog-detail .blog-detail-metadata-container .blog-metadata-left ul li a {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #989898;
  text-decoration: none;
}

.blog-detail .blog-detail-metadata-container .blog-metadata-left ul li a:hover {
  color: #093;
  text-decoration: none;
}

.blog-detail .blog-detail-metadata-container .blog-metadata-right {
  text-align: right;
}

.blog-detail h2 {
  margin: 10px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  color: #424242;
  letter-spacing: 0.5px;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .blog-detail h2 {
    font-size: 26px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 690px) {
  .blog-detail h2 {
    font-size: 24px;
    line-height: 30px;
  }
}

.blog-detail .yellow-bar {
  margin: 12px 0 15px 0;
}

.blog-detail p,
.blog-detail ul li,
.blog-detail ol li {
  margin: 0 0 12px 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #989898;
}

.blog-detail p a,
.blog-detail ul li a,
.blog-detail ol li a {
  color: #093;
  text-decoration: none;
}

.blog-detail p a:hover,
.blog-detail ul li a:hover,
.blog-detail ol li a:hover {
  color: #093;
  text-decoration: underline;
}

.sidebar-container { /* Used on blog post and case study pages */
  display: inline-block;
  float: right;
  width: 190px;
}

@media only screen and (max-width: 1000px) {
  .sidebar-container {
    float: none;
    clear: both;
    width: 100%;
  }
}

.blog-detail-sidebar {
  display: inline-block;
  float: left;
  width: 150px;
  margin: 129px 0 20px 40px;
  padding: 0;
}

@media only screen and (max-width: 1000px) {
  .blog-detail-sidebar {
    float: none;
    clear: both;
    width: 100%;
    margin: 20px 0 20px 0;
  }
}

.blog-detail-sidebar:last-child {
  margin: 0 0 20px 40px;
}

@media only screen and (max-width: 1000px) {
  .blog-detail-sidebar:first-child {
    padding-top: 10px;
  }

  .blog-detail-sidebar:last-child {
    margin: 0 0 20px 0;
  }
}

.blog-detail-sidebar h3 {
  margin: 0 0 10px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0.5px;
}

.blog-detail-sidebar ul {
  margin: 0;
  padding: 10px 0 0 0;
  list-style-type: none;
}

.blog-detail-sidebar ul li {
  margin: 0 0 10px 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #989898;
}

.blog-detail-sidebar ul li a {
  color: #989898;
  text-decoration: none;
}

.blog-detail-sidebar ul li a:hover {
  color: #093;
  text-decoration: none;
}

.case-studies-container {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}

.case-study { /* blocks on Services > Installation/International pages */
  display: inline-block;
  position: relative;
  float: left;
  width: 20%;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: #093;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .case-study {
    width: 25%;
  }

  .case-studies-container a:nth-child(5) { /* hide 5th case study */
    display: none;
  }
}

@media only screen and (max-width: 690px) {
  .case-study {
    width: 50%;
  }

  .case-study:nth-child(3n) {
    clear: both;
    float: left;
  }

  .case-studies-container a:nth-child(5) { /* hide 5th case study */
    display: none;
  }
}

.case-study img {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  opacity: 1;
}

.case-study:hover img {
  opacity: 0.4;
}

.case-study .case-study-titles-container {
  position: absolute;
  top: calc(50% - 36px); /* 36px = combined height of h4 and h5's line heights inside */
  display: table-cell;
  width: 80%;
  height: 80%;
  margin: 10%;
  opacity: 0;
}

@media only screen and (max-width: 1000px) {
  .case-study .case-study-titles-container {
    top: calc(50% - 40px); /* 36px = combined height of h4 and h5's line heights inside */
    opacity: 1;
  }
}

.case-study .case-study-titles-container h4 {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 1000px) {
  .case-study .case-study-titles-container h4 {
    padding-top: 5px;
    font-weight: 300;
    background-color: #093;
  }

  .case-study:hover .case-study-titles-container h4 {
    background-color: transparent;
  }
}

.case-study .case-study-titles-container h5 {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: #fff;
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .case-study .case-study-titles-container h5 {
    padding-bottom: 5px;
    font-weight: 300;
    background-color: #093;
  }

  .case-study:hover .case-study-titles-container h5 {
    background-color: transparent;
  }
}

.case-study:hover .case-study-titles-container {
  opacity: 1;
}

.case-study-detail {
  margin-top: 50px;
}

.case-study-detail-sidebar-first {
  margin: 0 0 20px 40px;
  padding-top: 123px;
}

.case-study-detail-sidebar {
  margin: 0 0 20px 40px;
}

@media only screen and (max-width: 1000px) {
  .case-study-detail-sidebar-first,
  .case-study-detail-sidebar {
    width: 100%;
    margin-left: 0;
    padding-top: 10px;
  }

  .case-study-detail-sidebar-first {
    padding-top: 30px !important;
  }
}

.case-study-detail-sidebar-first h4,
.case-study-detail-sidebar h4 {
  margin-bottom: 8px;
  font-family: 'Montserrat', sans-serif;
}

.case-study-detail-sidebar-first ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.case-study-detail-sidebar-first ul li,
.case-study-detail-sidebar p {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: #989898;
  text-align: left;
}

.case-study-detail-sidebar-first ul li {
  margin-bottom: 5px;
}

.case-study-detail-sidebar-first ul li:last-child {
  margin-bottom: 0;
}

.downloads-intro { /* also used for Events page intro */
  display: inline-block;
  float: left;
  width: 75%;
  margin-bottom: 10px;
  padding-right: 25%;
}

@media only screen and (max-width: 1000px) {
  .downloads-intro {
    display: inline-block;
    float: left;
    width: 100%;
    margin-bottom: 10px;
    padding-right: 0;
  }
}

.downloads-intro h2 {
  margin: 0 0 10px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  color: #424242;
  letter-spacing: 0.5px;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .downloads-intro h2 {
    font-size: 26px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 690px) {
  .downloads-intro h2 {
    font-size: 24px;
    line-height: 30px;
  }
}

.downloads-intro .yellow-bar {
  margin: 12px 0 15px 0;
}

@media only screen and (max-width: 1000px) {
  .downloads-intro .yellow-bar {
    display: inline-block;
  }
}

.downloads-intro p {
  margin: 0 0 12px 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #989898;
}

.downloads-intro ul li {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #989898;
}

.downloads-intro ul li a {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #093;
  text-decoration: none;
}

.downloads-intro ul li a:hover {
  text-decoration: underline;
}

.downloads-intro p a {
  color: #093;
  text-decoration: none;
}

.downloads-intro p a:hover {
  color: #093;
  text-decoration: underline;
}

.downloads-intro .intro-buttons {
  margin: 15px 0 0 0;
  padding: 0;
}

.downloads-intro .intro-buttons p {
  margin: 0;
  padding: 0;
}

.downloads-intro .intro-buttons .medium-button:first-child {
  margin-right: 20px;
}

@media only screen and (max-width: 690px) {
  .downloads-intro .intro-buttons .medium-button:first-child {
    clear: both;
    margin-bottom: 20px;
  }
}

.downloads-intro select {
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  color: #424242;
}

/* @-moz-document url-prefix() { /* Firefox fix to hide dropdown arrow */
.downloads-intro select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

/* } */

.downloads-intro .dropdown {
  width: 250px;
  height: 30px;
  margin-top: 5px;
  padding: 5px 10px;
  overflow: hidden;
  background: url("images/select-arrow.png") right no-repeat #f2f2f2;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #424242;
  border: 1px solid #989898;
  border-radius: 0;
}

.downloads-category {
  display: block;
  margin-bottom: 70px;
}

.resource-entry-container {
  display: block;
  width: 910px;
  margin-bottom: 20px;
  padding: 25px;
  background-color: #fff;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .resource-entry-container {
    width: 92%;
    padding: 3% 4%;
    overflow: auto;
  }
}

@media only screen and (max-width: 691px) {
  .resource-entry-container {
    width: 90%;
    padding: 5%;
    overflow: auto;
  }
}

.resource-entry-left {
  display: inline-block;
  float: left;
  width: 31px;
  height: 40px;
  margin-right: 30px;
  background: url("images/download-icon.png") no-repeat;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .resource-entry-left {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 690px) {
  .resource-entry-left {
    display: none;
  }
}

.resource-entry-middle {
  display: inline-block;
}

@media only screen and (max-width: 1000px) {
  .resource-entry-middle {
    width: auto;
    float: left;
  }
}

.resource-entry-middle h3 {
  display: block;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #989898;
}

.resource-entry-middle p {
  display: block;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0.25px;
}

.resource-entry-right {
  display: inline-block;
  float: right;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .resource-entry-right {
    display: block;
    float: none;
    clear: both;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 690px) {
  .resource-entry-right {
    display: block;
    float: none;
    clear: both;
    padding-top: 15px;
  }
}

.resource-entry-right a .medium-button {
  margin-top: -3px;
}

.resource-entry-right a .medium-button [class*="fa-"] {
  top: -2px;
  left: -1px;
}

.events-intro {
  padding-bottom: 20px;
}

.events-spacer {
  display: block;
  clear: both;
  height: 50px;
}

.events-left-odd {
  display: block;
  float: left;
  width: 320px;
  min-height: 320px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 1000px) {
  .events-left-odd {
    display: none;
  }
}

.events-right-odd {
  display: inline-block;
  float: left;
  width: 640px;
  min-height: 290px;
  padding-top: 30px;
  background-color: #fff;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .events-right-odd {
    display: block;
    height: 290px;
  }
}

@media only screen and (max-width: 1000px) {
  .events-right-odd {
    display: block;
    float: none;
    clear: both;
    width: 100%;
    min-height: 100px;
    padding-top: 0;
  }
}

.events-right-container-odd {
  width: 540px;
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .events-right-container-odd {
    display: inline-block;
    width: 80%;
    padding: 5% 10%;
  }
}

@media only screen and (max-width: 690px) {
  .events-right-container-odd {
    display: inline-block;
    width: 80%;
    padding: 8% 10%;
  }
}

.events-right-odd .events-right-container-odd h3 {
  width: 540px;
  margin: 10px 0 0 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0.5px;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .events-right-odd .events-right-container-odd h3 {
    padding-top: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .events-right-odd .events-right-container-odd h3 {
    float: none;
    clear: both;
    width: 100%;
    margin: 0;
  }
}

.events-right-odd .events-right-container-odd h5 {
  width: 540px;
  margin: 10px 0 0 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  color: #989898;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .events-right-odd .events-right-container-odd h5 {
    padding-top: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .events-right-odd .events-right-container-odd h5 {
    float: none;
    clear: both;
    width: 100%;
    margin: 5px 0 0 0;
  }
}

.events-right-odd .events-right-container-odd h5 .fa {
  top: -1px;
  margin-left: 10px;
  margin-right: 6px;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.events-right-odd .events-right-container-odd h5 .fa:first-child {
  top: -2px;
  margin-left: 0;
}

@media only screen and (max-width: 690px) {
  .events-right-odd .events-right-container-odd h5 .fa {
    margin-left: 0;
  }
}

.events-right-odd .events-right-container-odd .yellow-bar {
  margin: 15px 0;
}

.events-right-odd .events-right-container-odd p {
  width: 540px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #989898;
}

@media only screen and (max-width: 1000px) {
  .events-right-odd .events-right-container-odd p {
    float: none;
    clear: both;
    width: 100%;
  }
}

.events-right-odd .events-right-container-odd a {
  color: #093;
  text-decoration: none;
}

.events-right-odd .events-right-container-odd a:hover {
  color: #093;
  text-decoration: underline;
}

.events-left-even {
  float: left;
  width: 640px;
  min-height: 290px;
  padding-top: 30px;
  background-color: #fff;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .events-left-even {
    display: block;
    height: 290px;
  }
}

@media only screen and (max-width: 1000px) {
  .events-left-even {
    display: block;
    float: none;
    clear: both;
    width: 100%;
    min-height: 100px;
    padding-top: 0;
  }
}

.events-left-container-even {
  display: inline-block;
  width: 540px;
  padding-left: 50px;
  padding-right: 50px;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .events-left-even .events-left-container-even h3 {
    padding-left: 80px;
  }
}

@media only screen and (min-width: 691px) and (max-width: 1000px) {
  .events-left-container-even {
    display: inline-block;
    width: 80%;
    padding: 5% 10%;
  }
}

@media only screen and (max-width: 690px) {
  .events-left-container-even {
    display: inline-block;
    width: 80%;
    padding: 8% 10%;
  }
}

.events-left-even .events-left-container-even h3 {
  float: right;
  width: 540px;
  margin: 10px 0 0 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0.5px;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .events-left-even .events-left-container-even h3 {
    padding-top: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .events-left-even .events-left-container-even h3 {
    float: none;
    clear: both;
    width: 100%;
    margin: 0;
  }
}

.events-left-even .events-left-container-even h5 {
  float: right;
  width: 540px;
  margin: 10px 0 0 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  color: #989898;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

@-moz-document url-prefix() { /* Firefox fix for central vertical alignment and full height grey bg */
  .events-left-even .events-left-container-even h5 {
    padding-top: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .events-left-even .events-left-container-even h5 {
    float: none;
    clear: both;
    width: 100%;
    margin: 5px 0 0 0;
  }
}

.events-left-even .events-left-container-even h5 .fa {
  top: -1px;
  margin-left: 10px;
  margin-right: 6px;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.events-left-even .events-left-container-even h5 .fa:first-child {
  top: -2px;
  margin-left: 0;
}

@media only screen and (max-width: 690px) {
  .events-left-even .events-left-container-even h5 .fa {
    margin-left: 0;
  }
}

.events-left-even .events-left-container-even .yellow-bar-container {
  float: right;
  width: 29px;
  padding-right: 511px; /* maintain 29px width */
}

@media only screen and (max-width: 1000px) {
  .events-left-even .events-left-container-even .yellow-bar-container {
    float: none;
    clear: both;
    width: 29px;
    padding-right: 0;
  }
}

.events-left-even .events-left-container-even .yellow-bar {
  margin: 15px 0;
}

.events-left-even .events-left-container-even p {
  float: right;
  clear: both;
  width: 540px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #989898;
}

@media only screen and (max-width: 1000px) {
  .events-left-even .events-left-container-even p {
    float: none;
    clear: both;
    width: 100%;
  }
}

.events-left-even .events-left-container-even a {
  color: #093;
  text-decoration: none;
}

.events-left-even .events-left-container-even a:hover {
  color: #093;
  text-decoration: underline;
}

.events-right-even {
  display: block;
  float: left;
  width: 320px;
  min-height: 320px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 1000px) {
  .events-right-even {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .events-left-even .events-left-container-even .yellow-bar-container .yellow-bar,
  .events-right-odd .events-right-container-odd .yellow-bar {
    display: inline-block;
  }
}

.event-sign-up-button {
  display: inline-block;
  padding-top: 5px;
}

@media only screen and (max-width: 1000px) {
  .event-sign-up-button {
    padding-top: 20px;
  }
}

.content-left { /* Contact page 50/50 layout */
  display: inline-block;
  float: left;
  width: 420px;
  margin-bottom: 60px;
  padding-right: 60px;
}

@media only screen and (max-width: 1000px) {
  .content-left {
    float: none;
    clear: both;
    width: 100%;
    margin-bottom: 20px;
    padding-right: 0;
  }
}

.content-left h1 {
  margin: 0 0 10px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  color: #424242;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 1000px) {
  .content-left h1 {
    margin: 5px 0;
    font-size: 26px;
    line-height: 32px;
  }
}

.content-left .yellow-bar {
  margin: 12px 0 15px 0;
}

.content-left h3 {
  margin: 0 0 5px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0.25px;
}

.content-left p {
  margin: 0 0 10px 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #989898;
}

.content-left p a {
  color: #093;
  text-decoration: none;
}

.content-left p a:hover {
  color: #093;
  text-decoration: underline;
}

.content-left .contact-contacts {
  display: inline-block;
  float: none;
  clear: both;
  text-align: left;
}

.content-left .contact-contacts .contact {
  display: block;
  float: none;
  clear: both;
  margin: 0;
  padding-top: 15px;
}

.content-left .contact-contacts .location {
  display: block;
  float: none;
  clear: both;
  margin: 0;
  padding-top: 15px;
}

.content-left .contact-contacts .hours {
  display: block;
  float: none;
  clear: both;
  margin: 0;
  padding-top: 15px;
}

.content-left .contact-contacts .icon-block {
  display: block;
  float: left;
}

.content-left .contact-contacts .icon-block img {
  display: block;
  clear: both;
  margin-right: 12px;
}

.content-left .contact-contacts .text-block {
  display: block;
  float: left;
}

.content-left .contact-contacts .text-block p.line-1,
.content-left .contact-contacts .text-block p.line-1 a {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  color: #424242;
  text-decoration: none;
}

.content-left .contact-contacts .text-block p.line-1 a:hover {
  color: #093;
  text-decoration: none;
}

.content-left .contact-contacts .text-block p.line-2,
.content-left .contact-contacts .text-block p.line-2 a {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
  color: #989898;
  text-decoration: none;
}

.content-left .contact-contacts .text-block p.line-2 a:hover {
  color: #093;
  text-decoration: none;
}

.content-right {
  display: inline-block;
  float: left;
  margin-bottom: 60px;
  width: 480px;
}

@media only screen and (max-width: 1000px) {
  .content-right {
    float: none;
    clear: both;
    width: 100%;
  }
}

.content-right .contact-form {
}

.content-right .contact-form .form-col-left {
  display: inline-block;
  float: left;
  width: 225px;
  margin-right: 25px;
}

.content-right .contact-form .form-col-right {
  display: inline-block;
  float: left;
  width: 225px;
}

@media only screen and (max-width: 1000px) {
  .content-right .contact-form .form-col-left,
  .content-right .contact-form .form-col-right {
    display: inline-block;
    float: none;
    clear: both;
    width: 100%;
    margin-right: 0;
  }
}

#nf-form-1-cont {
  margin-top: 0;
  padding: 5px 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #424242;
  background-color: #f2f2f2;
  border: 0;
}

.nf-form-fields-required {
  margin-bottom: 5px !important;
}

.nf-form-content {
  margin-top: 12px !important;
}

#ninja_forms_required_items,
.nf-field-container {
  margin-bottom: 10px !important;
}

.nf-field-label label {
  margin-bottom: 0 !important;
  font-weight: 300 !important;
}

.label-above .nf-field-label {
  margin-top: 8px !important;
  margin-bottom: 0 !important;
}

.nf-field-element input[type="text"],
.nf-field-element textarea,
.nf-field-element input[type="email"] {
  margin-top: 0;
  padding: 5px 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #424242;
  background-color: #fff;
  border: 0;
}

#nf-field-3 { /* textarea */
  height: 120px !important;
}

#nf-field-5 { /* dropdown */
  height: 30px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  background: #fff !important;
  color: #989898 !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.nf-field::-webkit-input-placeholder { /* WebKit browsers */
  color: #989898 !important;
}

.nf-field:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #989898 !important;
}

.nf-field::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #989898 !important;
}

.nf-field:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #989898 !important;
}

.nf-field-container.submit-container {
  margin-top: 12px !important;
}

.nf-field-element input[type="button"],
.nf-field-element input.ninja-forms-field.nf-element[type="button"] {
  display: inline-block;
  float: left;
  width: auto;
  height: 38px;
  padding: 13px 16px 13px 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  line-height: 13px;
  font-weight: 300;
  letter-spacing: 0.25px;
  color: #fff;
  text-transform: uppercase;
  background: #16a542;
  border: 0;
  border-radius: 5px;
  -webkit-transition: all 0.2s !important;
  -moz-transition: all 0.2s !important;
  -ms-transition: all 0.2s !important;
  -o-transition: all 0.2s !important;
  transition: all 0.2s !important;
}

.nf-field-element input[type="button"]:hover,
.nf-field-element input.ninja-forms-field.nf-element[type="button"]:hover {
  color: #fff;
  background: #093;
  border: 0;
}

.nf-error-msg {
  color: #c05375 !important;
}

.nf-error-msg.nf-error-field-errors {
  margin-top: 20px !important;
}

.nf-response-msg p {
  padding: 4px 12px;
  font-weight: 400 !important;
  color: #c05375 !important;
  background: #f1f1f1;
}

.content-right .contact-disclaimer {
  width: 90%;
  margin: 4px 0 0 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 9px;
  line-height: 13px;
  font-weight: 400;
  color: #989898;
}

@media only screen and (max-width: 690px) {
  .content-right .contact-disclaimer {
    display: block;
    float: none;
    clear: both;
    width: 100%;
    padding-top: 15px;
    font-size: 10px;
    line-height: 14px;
  }
}

.content-right .contact-disclaimer a {
  text-transform: none;
  color: #093;
}

.content-right .contact-disclaimer a:hover {
  text-transform: underline;
  color: #093;
}

.search-results {
  margin-bottom: 60px;
}

.search-results ul {
  margin-left: 0;
  list-style: none;
}

.search-results ul li {
  margin: 5px 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #989898;
}

.search-results ul li a {
  color: #989898;
  text-decoration: none;
}

.search-results ul li a:hover {
  color: #093;
  text-decoration: none;
}

// Filters on case study pages

/*  Needed for filters */

.hide { /* You can play with the seconds to change the "animation" */
  animation: hide 0.2s ease 0s 1 normal forwards;
  transform-origin: center;
}

.show { /* You can play with the seconds to change the "animation" */
  animation: show 0.2s ease 0s 1 normal forwards;
  transform-origin: center;
}

@keyframes hide {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
    width: 0;
    height: 0;
    margin: 0;
  }
}

@keyframes show {
  0% {
    transform: scale(0);
    width: 0;
    height: 0;
    margin: 0;
  }

  100% {
    transform: scale(1);
  }
}

/*  // Needed for filters */

/* For decoration only */
body {
  font-family: sans-serif;
}

.filters.case {
  background-color: #f2f2f2;
  margin: 0;
  padding: 0 0 30px 0;

  .filter {
    padding: 0 30px 0 0;
    margin: 0 30px 0 0;
    border-right: 1px solid #000;
    font-family: "Montserrat", sans-serif;

    &:last-child {
      margin: 0;
      border: 0;
      padding: 0;
    }
  }
}

.project {
  width: 280px;
  margin: 20px;
  float: left;
  position: relative;

  .case-cat-icon {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .project img {
    width: 100%;
  }

  .project {
    width: 280px;
    margin: 0 auto 30px auto;
    padding-top: 20px;
    float: none !important;
    height: 100%;
    overflow: hidden;
  }
}

.project .blog-listing-item-text-container {
  display: inline-block;
  float: left;
  width: 220px;
  margin: 0;
  padding: 20px 30px;
  background-color: #fff;
}

@media only screen and (max-width: 1000px) {
  .project .blog-listing-item-text-container {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.project .blog-listing-item-text-container .blog-metadata-left,
.project .blog-listing-item-text-container .blog-metadata-right {
  display: inline-block;
  float: left;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #989898;
}

.project .blog-listing-item-text-container .blog-metadata-left {
  width: 45%;
}

.project .blog-listing-item-text-container .blog-metadata-right {
  width: 55%;
}

.project .blog-listing-item-text-container .blog-metadata-left ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.project .blog-listing-item-text-container .blog-metadata-left ul li {
  margin: 0;
  padding: 0;
}

.project .blog-listing-item-text-container .blog-metadata-left ul li a {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #989898;
  text-decoration: none;
}

.project .blog-listing-item-text-container .blog-metadata-left ul li a:hover {
  color: #093;
  text-decoration: none;
}

.project .blog-listing-item-text-container .blog-metadata-right {
  text-align: right;
}

.project .blog-listing-item-text-container h3 {
  margin: 0 0 10px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 0.5px;
}

.project .blog-listing-item-text-container h3 a {
  color: #424242;
  text-decoration: none;
}

.project .blog-listing-item-text-container h3 a:hover {
  color: #093;
  text-decoration: none;
}

.project .blog-listing-item-text-container p {
  margin-bottom: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #989898;
}

.project img {
  display: block;
  clear: both;
  width: 280px;
  height: auto;
  border-bottom: 3px solid #ffcb01;
}

@media only screen and (max-width: 1000px) {
  .project img {
    width: 100%;
  }
}

.project img:hover {
  /* opacity: 0.8; */
  border-bottom: 3px solid #093;
}

.filters {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.filter {
  margin: 20px;
}

.filter:hover {
  cursor: pointer;
}

.card-payment-logos {
  ul {
    padding: 0;
    margin: 0 0 50px 0;

    li {
      display: inline-block;
    }
  }
}

/* // For decoration only */
