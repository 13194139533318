.wholesale-registration,
.registration,
.login {
  padding-top: 90px;
  padding-bottom: 90px;

  .woocommerce {
    padding-top: 0;
    padding-bottom: 0;
  }

  h1 {
    font-size: 44px;
    line-height: 1.38;
    font-weight: 600;
    color: $dark-green;
  }

  label {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: $dark-green;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .required {
    color: $dark-green;
  }

  .terms-and-condition-container {
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .button {
    float: left;
    width: auto;
    padding: 12px !important;
    margin-right: 20px;
  }

  #log-in,
  #lost-password,
  .register_link,
  .lost_password_link {
    line-height: 72px;
    margin-right: 20px;
    text-decoration: none;
    color: $dark-green;
    font-weight: 600;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }
}

.wholesale-reg-button {
  float: right !important;
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .wholesale-registration {
    padding: 0 25px;

    .col-4,
    .col-8 {
      width: 100%;
      flex-basis: 100%;
    }
  }

  input {
    width: 97%;
  }
}
