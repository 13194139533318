.navigation {
  .inner-wrapper {
    width: 1000px;

    .wpmenucart-contents {
      line-height: 40px;
      color: #424242;
      font-family: inherit;
      font-size: 12px;
      text-decoration: none;

      i {
        color: $dark-green;
      }
      
      .amount {
        display: none !important;
      }
    }
  }
}
